import * as React from 'react';
import * as styles from '../styles/toggle-text.module.css';

const ToggleText = ({
  text, dataTarget, dropdownIcon, className,
}) => (
  <>
    <button
      type="button"
      onClick={function () {
        if (document.getElementById(dataTarget).style.display === 'none') {
          document.getElementById(dataTarget).style.display = 'block';
          document.getElementById(dropdownIcon).style.transform = 'rotate(180deg)';
        } else {
          document.getElementById(dataTarget).style.display = 'none';
          document.getElementById(dropdownIcon).style.transform = 'rotate(0deg)';
        }
      }}
      className={`btn-group navbar-toggler collapsed ${styles.btnContainer} ${className}`}
      style={{ alignItems: 'center', width: '100%', justifyContent: 'space-between' }}
    >
      <h2 className={`nav-link ${styles.navLinkFont}`}>
        {text}
      </h2>
      <span id={dropdownIcon} className={`dropdown-icon ${styles.dropdownIcon} ${styles.navLinkFont}`}>▼</span>
    </button>
    <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
  </>
);

export default ToggleText;
