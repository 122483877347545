import * as React from 'react';
import * as hoverHighlightTextStyles from '../styles/hover-highlight-text.module.css';

const HoverHighlightText = ({ highlightText, normalText, style }) => (
  <div style={{ ...style, display: 'flex', alignItems: 'center' }}>
    <div className={`${hoverHighlightTextStyles.container} ${hoverHighlightTextStyles.highlightTextFont}`}><span>{highlightText}</span></div>
    <span className={hoverHighlightTextStyles.normalTextFont}>{normalText}</span>
  </div>
);

export default HoverHighlightText;
