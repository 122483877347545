import Aos from 'aos';
import 'aos/dist/aos.css';
import window from 'global';
import * as React from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import MyCarouselSlide from '../components/CarouselSlide';
import DropdownText from '../components/DropdownText';
import Footer from '../components/Footer';
import Head from '../components/Head';
import HoverHighlightText from '../components/HoverHighlightText';
import MainSubtitle from '../components/MainSubtitle';
import MainTitle from '../components/MainTitle';
import MobileNavbar from '../components/MobileNav';
import MyButton from '../components/MyButton';
import MyCarousel from '../components/MyCarousel';
import MyFuncButton from '../components/MyFuncButton';
import Navbar from '../components/NavBar';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import ToggleText from '../components/ToggleText';
import corpTrainIndexBgImg from '../images/corporate-training.webp';
import javaSlideBg from '../images/java-learn-coding.webp';
import learnCodeIndexBgImg from '../images/learn-coding-trait.webp';
import bgImg from '../images/learn-coding.webp';
import nexgenCurriculumTree from '../images/nexgen-curriculum-tree.png';
import nexgenSlideBg from '../images/nexgen-learn-coding.webp';
import pythonSlideBg from '../images/python-learn-coding.webp';
import * as panelStyles from '../styles/panel.module.css';

const IndexPage = () => {
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const [panelState, setPanelState] = React.useState({
    isNexGenPaneOpen: false,
    isPythonPaneOpen: false,
    isJavaPaneOpen: false,
  });

  React.useEffect(() => {
    Aos.init();
    Aos.refresh();
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [panelState.isNexGenPaneOpen, panelState.isJavaPaneOpen, panelState.isPythonPaneOpen]);

  return (
    <>
      {console.log(dimension.width)}
      <Head pageTitle="TechBoss" />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      {dimension.width > breakpoint ? (
        <Section backgroundImage={bgImg}>
          <SectionContentContainer>
            <MainTitle text="ACE H2 COMPUTING TODAY" />
            <br />
            <MainSubtitle text="Designed by experienced technologists. Built for aspiring coders" />
            <br />
            <MyButton link="/contact" title="JOIN NOW" />
          </SectionContentContainer>
        </Section>
      ) : (
        <Section backgroundImage={bgImg}>
          <SectionContentContainer customStyle={{ top: '25%' }}>
            <MainTitle text="ACE H2 COMPUTING TODAY" />
            <br />
            <MainSubtitle text="Designed by experienced technologists. Built for aspiring coders" />
            <br />
            <MyButton link="/contact" title="JOIN NOW" />
          </SectionContentContainer>
        </Section>
      )}
      {/* {dimension.width > breakpoint ? (
        <Section backgroundImage={corpTrainIndexBgImg}>
          <SectionContentContainer customStyle={{ left: '45%' }}>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="CORPORATE TRAINING" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle text="We provide training for Excel VBA, Python, Java and Tech-ducation" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MyButton link="/corporate" title="CONTACT US" />
            </div>
          </SectionContentContainer>
        </Section>
      ) : (
        <Section backgroundImage={corpTrainIndexBgImg}>
          <SectionContentContainer>
            <div data-aos="fade-down" data-aos-duration="500">
              <MainTitle text="CORPORATE TRAINING" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MainSubtitle text="We provide training for Excel VBA, Python, Java and Tech-ducation" />
            </div>
            <br />
            <div data-aos="fade-down" data-aos-duration="500">
              <MyButton link="/corporate" title="CONTACT US" />
            </div>
          </SectionContentContainer>
        </Section>
      )} */}
      <Section backgroundImage={corpTrainIndexBgImg}>
        <SectionContentContainer>
          <div data-aos="fade-down" data-aos-duration="500">
            <MainTitle text="CORPORATE TRAINING" />
          </div>
          <br />
          <div data-aos="fade-down" data-aos-duration="500">
            <MainSubtitle text="We provide training for Excel VBA, Python, Java and Tech-ducation" />
          </div>
          <br />
          <div data-aos="fade-down" data-aos-duration="500">
            <MyButton link="/corporate" title="CONTACT US" />
          </div>
        </SectionContentContainer>
      </Section>
      <MyCarousel
        carouselID="carouselIndex"
        slideArray={[
          <MyCarouselSlide active="active">
            <Section backgroundImage={nexgenSlideBg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="NEXGEN COMPUTING" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="For GCE O and A level’s students" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MyFuncButton title="LEARN MORE" onClick={() => setPanelState({ isNexGenPaneOpen: true })} />
                </div>
                {dimension.width > breakpoint ? (
                  <SlidingPane
                    className={panelStyles.panel}
                    overlayClassName={panelStyles.overlay}
                    width="60%"
                    isOpen={panelState.isNexGenPaneOpen}
                    onRequestClose={() => {
                      setPanelState({ isNexGenPaneOpen: false });
                    }}
                    hideHeader
                  >
                    {/* <MainTitle text="ROADMAP" /> */}
                    <img
                      src={nexgenCurriculumTree}
                      alt="NexGen Curriculum Tree"
                      style={{
                        width: '50vw', height: '75vh', marginLeft: '7%', marginBottom: '5%', marginTop: '5%',
                      }}
                    />
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                      <MyButton link="/nexgen" title="FIND OUT MORE" />
                      <MyFuncButton
                        onClick={() => {
                          setPanelState({ isNexGenPaneOpen: false });
                        }}
                        title="CLOSE"
                      />
                    </div>
                  </SlidingPane>
                ) : (
                  <SlidingPane
                    className={panelStyles.panel}
                    overlayClassName={panelStyles.overlay}
                    width="100%"
                    isOpen={panelState.isNexGenPaneOpen}
                    onRequestClose={() => {
                      setPanelState({ isNexGenPaneOpen: false });
                    }}
                    hideHeader
                  >
                    {/* <MainTitle text="ROADMAP" /> */}
                    <img
                      src={nexgenCurriculumTree}
                      alt="NexGen Curriculum Tree"
                      style={{
                        width: '82vw', height: '55vh', marginBottom: '35%', marginTop: '35%',
                      }}
                    />
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                      <MyButton link="/nexgen" title="FIND OUT MORE" style={{ fontSize: 'calc(2.2vh + 2.2vw)' }} />
                      <MyFuncButton
                        style={{ fontSize: 'calc(2.2vh + 2.2vw)' }}
                        onClick={() => {
                          setPanelState({ isNexGenPaneOpen: false });
                        }}
                        title="CLOSE"
                      />
                    </div>
                  </SlidingPane>
                )}
              </SectionContentContainer>
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={pythonSlideBg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="PYTHON CHIEF" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Go from zero to hero in 12 weeks" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MyFuncButton onClick={() => { setPanelState({ isPythonPaneOpen: true }); }} title="LEARN MORE" />
                </div>
              </SectionContentContainer>
              {dimension.width > breakpoint ? (
                <SlidingPane
                  className={panelStyles.panel}
                  overlayClassName={panelStyles.overlay}
                  width="60%"
                  isOpen={panelState.isPythonPaneOpen}
                  onRequestClose={() => {
                    setPanelState({ isPythonPaneOpen: false });
                  }}
                  hideHeader
                >
                  {/* <MainTitle text="PYTHON CHIEF" /> */}
                  <MainTitle text="COMING SOON" style={{ position: 'absolute', top: '45%', left: '32%' }} />
                  <div style={{
                    width: '90%', display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '5%',
                  }}
                  >
                    <MyButton link="/python" title="FIND OUT MORE" />
                    <MyFuncButton
                      onClick={() => {
                        setPanelState({ isPythonPaneOpen: false });
                      }}
                      title="CLOSE"
                    />
                  </div>
                </SlidingPane>
              ) : (
                <SlidingPane
                  className={panelStyles.panel}
                  overlayClassName={panelStyles.overlay}
                  width="100%"
                  isOpen={panelState.isPythonPaneOpen}
                  onRequestClose={() => {
                    setPanelState({ isPythonPaneOpen: false });
                  }}
                  hideHeader
                >
                  {/* <MainTitle text="PYTHON CHIEF" /> */}
                  <MainTitle text="COMING SOON" style={{ marginTop: '100%', marginBottom: '100%' }} />
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <MyButton link="/python" title="FIND OUT MORE" style={{ fontSize: 'calc(2.2vh + 2.2vw)' }} />
                    <MyFuncButton
                      style={{ fontSize: 'calc(2.2vh + 2.2vw)' }}
                      onClick={() => {
                        setPanelState({ isPythonPaneOpen: false });
                      }}
                      title="CLOSE"
                    />
                  </div>
                </SlidingPane>
              )}
            </Section>
          </MyCarouselSlide>,
          <MyCarouselSlide>
            <Section backgroundImage={javaSlideBg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="JAVA MASTER" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainSubtitle text="Become an industry ready software engineer" />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <MyFuncButton
                    onClick={() => {
                      setPanelState({ isJavaPaneOpen: true });
                    }}
                    title="LEARN MORE"
                  />
                </div>
              </SectionContentContainer>
              {dimension.width > breakpoint ? (
                <SlidingPane
                  className={panelStyles.panel}
                  overlayClassName={panelStyles.overlay}
                  width="60%"
                  isOpen={panelState.isJavaPaneOpen}
                  onRequestClose={() => {
                    setPanelState({ isJavaPaneOpen: false });
                  }}
                  hideHeader
                >
                  {/* <MainTitle text="JAVA MASTER" /> */}
                  <MainTitle text="COMING SOON" style={{ position: 'absolute', top: '45%', left: '32%' }} />
                  <div style={{
                    width: '90%', display: 'flex', justifyContent: 'space-evenly', position: 'absolute', bottom: '5%',
                  }}
                  >
                    <MyButton link="/java" title="FIND OUT MORE" />
                    <MyFuncButton
                      onClick={() => {
                        setPanelState({ isJavaPaneOpen: false });
                      }}
                      title="CLOSE"
                    />
                  </div>
                </SlidingPane>
              ) : (
                <SlidingPane
                  className={panelStyles.panel}
                  overlayClassName={panelStyles.overlay}
                  width="100%"
                  isOpen={panelState.isJavaPaneOpen}
                  onRequestClose={() => {
                    setPanelState({ isJavaPaneOpen: false });
                  }}
                  hideHeader
                >
                  {/* <MainTitle text="JAVA MASTER" /> */}
                  <MainTitle text="COMING SOON" style={{ marginTop: '100%', marginBottom: '100%' }} />
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <MyButton link="/java" title="FIND OUT MORE" style={{ fontSize: 'calc(2.2vh + 2.2vw)' }} />
                    <MyFuncButton
                      style={{ fontSize: 'calc(2.2vh + 2.2vw)' }}
                      onClick={() => {
                        setPanelState({ isJavaPaneOpen: false });
                      }}
                      title="CLOSE"
                    />
                  </div>
                </SlidingPane>
              )}
            </Section>
          </MyCarouselSlide>,
        ]}
      />
      {/* {dimension.width <= breakpoint
        ? (
          <>
            <Section backgroundImage={learnCodeIndexBgImg}>
              <SectionContentContainer>
                <div data-aos="fade-down" data-aos-duration="500">
                  <MainTitle text="Learn Coding" style={{ textAlign: 'left' }} />
                </div>
                <br />
                <div data-aos="fade-down" data-aos-duration="500">
                  <HoverHighlightText highlightText="2x" normalText="faster" />
                </div>
                <div data-aos="fade-down" data-aos-duration="500">
                  <HoverHighlightText highlightText="3x" normalText="more effective" />
                </div>
              </SectionContentContainer>
            </Section>
            <div style={{
              backgroundColor: 'black',
              paddingTop: '30vh',
              paddingBottom: '30vh',
            }}
            >
              <div data-aos="fade-down" data-aos-duration="500">
                <ToggleText text="Learn from actual practitioners" dataTarget="firstToggle" dropdownIcon="icon1" />
                <div
                  id="firstToggle"
                  style={{ display: 'none' }}
                >
                  <DropdownText text="Our instructors teach you how to apply computing theory into real-life applications" />
                  <DropdownText text="Our curriculum is built by talents from top companies and taught by professional developers" />
                  <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
                </div>
              </div>
              <div data-aos="fade-down" data-aos-duration="500">
                <ToggleText text="Get better value for your time and money" dataTarget="secondToggle" dropdownIcon="icon2" />
                <div id="secondToggle" style={{ display: 'none' }}>
                  <DropdownText text="We teach in 10 lessons what others teach in 20" />
                  <DropdownText text="TechBoss: $80/h for 15 two-hour lessons ($2400)" />
                  <DropdownText text="Others: $60/h for 30 two-hour lessons ($3600)" />
                  <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
                </div>
              </div>
              <div data-aos="fade-down" data-aos-duration="500">
                <ToggleText text="Engage in an interactive classroom" dataTarget="thirdToggle" dropdownIcon="icon3" />
                <div id="thirdToggle" style={{ display: 'none' }}>
                  <DropdownText text="Collaborate and co-create with fellow students and instructors" />
                  <DropdownText text="Build and deploy your applications as a team through our projects" />
                  <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
                </div>
              </div>
            </div>
          </>
        )
        : (
          <div className="row" style={{ minHeight: '100vh', height: 'auto', backgroundColor: 'black' }}>
            <div className="col-6" style={{ paddingTop: '30vh', paddingLeft: '15vw' }}>
              <div data-aos="fade-right" data-aos-duration="500">
                <MainTitle text="Learn Coding" />
              </div>
              <br />
              <div data-aos="fade-right" data-aos-duration="500">
                <HoverHighlightText highlightText="2x" normalText="faster" />
              </div>
              <div data-aos="fade-right" data-aos-duration="500">
                <HoverHighlightText highlightText="3x" normalText="more effective" />
              </div>
            </div>
            <div className="col-6" style={{ paddingTop: '30vh', paddingBottom: '30vh', paddingRight: '15vw' }}>
              <div data-aos="fade-left" data-aos-duration="500">
                <ToggleText text="Learn from actual practitioners" dataTarget="firstToggle" dropdownIcon="icon1" />
                <div
                  id="firstToggle"
                  style={{ display: 'none' }}
                >
                  <DropdownText text="Our instructors teach you how to apply computing theory into real-life applications" />
                  <DropdownText text="Our curriculum is built by talents from top companies and taught by professional developers" />
                  <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
                </div>
              </div>
              <div data-aos="fade-left" data-aos-duration="500">
                <ToggleText text="Get better value for your time and money" dataTarget="secondToggle" dropdownIcon="icon2" />
                <div id="secondToggle" style={{ display: 'none' }}>
                  <DropdownText text="We teach in 10 lessons what others teach in 20" />
                  <DropdownText text="TechBoss: $80/h for 15 two-hour lessons ($2400)" />
                  <DropdownText text="Others: $60/h for 30 two-hour lessons ($3600)" />
                  <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
                </div>
              </div>
              <div data-aos="fade-left" data-aos-duration="500">
                <ToggleText text="Engage in an interactive classroom" dataTarget="thirdToggle" dropdownIcon="icon3" />
                <div id="thirdToggle" style={{ display: 'none' }}>
                  <DropdownText text="Collaborate and co-create with fellow students and instructors" />
                  <DropdownText text="Build and deploy your applications as a team through our projects" />
                  <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
                </div>
              </div>
            </div>
          </div>
        )} */}
      <Section backgroundImage={learnCodeIndexBgImg}>
        <SectionContentContainer>
          <div data-aos="fade-down" data-aos-duration="500">
            <MainTitle text="Learn Coding" style={{ textAlign: 'left' }} />
          </div>
          <br />
          <div data-aos="fade-down" data-aos-duration="500">
            <HoverHighlightText highlightText="2x" normalText="faster" />
          </div>
          <div data-aos="fade-down" data-aos-duration="500">
            <HoverHighlightText highlightText="3x" normalText="more effective" />
          </div>
        </SectionContentContainer>
      </Section>
      <div style={{
        backgroundColor: 'black',
        padding: '30vh 5vw',
      }}
      >
        <div data-aos="fade-down" data-aos-duration="500">
          <ToggleText text="Learn from actual practitioners" dataTarget="firstToggle" dropdownIcon="icon1" />
          <div
            id="firstToggle"
            style={{ display: 'none' }}
          >
            <DropdownText text="Our instructors teach you how to apply computing theory into real-life applications" />
            <DropdownText text="Our curriculum is built by talents from top companies and taught by professional developers" />
            <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
          </div>
        </div>
        <div data-aos="fade-down" data-aos-duration="500">
          <ToggleText text="Get better value for your time and money" dataTarget="secondToggle" dropdownIcon="icon2" />
          <div id="secondToggle" style={{ display: 'none' }}>
            <DropdownText text="We teach in 10 lessons what others teach in 20" />
            <DropdownText text="TechBoss: $80/h for 15 two-hour lessons ($2400)" />
            <DropdownText text="Others: $60/h for 30 two-hour lessons ($3600)" />
            <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
          </div>
        </div>
        <div data-aos="fade-down" data-aos-duration="500">
          <ToggleText text="Engage in an interactive classroom" dataTarget="thirdToggle" dropdownIcon="icon3" />
          <div id="thirdToggle" style={{ display: 'none' }}>
            <DropdownText text="Collaborate and co-create with fellow students and instructors" />
            <DropdownText text="Build and deploy your applications as a team through our projects" />
            <div className="dropdown-divider" style={{ borderTop: 'calc(0.05vh + 0.05vw) solid white' }} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default IndexPage;
