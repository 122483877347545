import * as React from 'react';

const MyCarousel = ({
  carouselID, slideArray, className, style,
}) => (
  <div id={carouselID} className={`carousel slide ${className}`} style={style} data-interval="false">
    <div className="carousel-indicators">
      {slideArray.filter((e, i) => i === 0).map((slide, i) => (
        <button type="button" data-bs-target={`#${carouselID}`} data-bs-slide-to={i} className="active" aria-current="true" aria-label={`Slide ${i + 1}`} />
      ))}
      {slideArray.filter((e, i) => i !== 0).map((slide, i) => (
        <button type="button" data-bs-target={`#${carouselID}`} data-bs-slide-to={i + 1} aria-label={`Slide ${i + 2}`} />
      ))}
    </div>
    <div className="carousel-inner">
      {slideArray.map((slide) => slide)}
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target={`#${carouselID}`} data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target={`#${carouselID}`} data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </div>
);

export default MyCarousel;
