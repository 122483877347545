import { Link } from 'gatsby';
import * as React from 'react';
import * as btnStyles from '../styles/button.module.css';

const MyButton = ({ title, link, style }) => (
  <Link to={link} className={`${btnStyles.btnContainer} ${btnStyles.btnTitleFont}`} style={style}>
    <span>{title}</span>
  </Link>
);

export default MyButton;
