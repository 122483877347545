import * as React from 'react';
import * as dropdownStyles from '../styles/toggle-text.module.css';

const DropdownText = ({ text }) => (
  <>
    <p className={dropdownStyles.dropdownText}>
      {text}
    </p>
  </>
);

export default DropdownText;
