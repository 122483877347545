/* eslint-disable react/button-has-type */
import * as React from 'react';
import * as btnStyles from '../styles/button.module.css';

const MyFuncButton = ({
  id, title, onClick, style, type, className,
}) => (
  <button type={type} id={id} onClick={onClick} className={`${btnStyles.btnContainer} ${btnStyles.btnTitleFont} ${className}`} style={style}>
    <span>{title}</span>
  </button>
);

export default MyFuncButton;
